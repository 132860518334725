import { assert } from '../utils';

/**
 * IMPORTANT: This maps to the LLM enum in LlamaIndexer,
 * if you update this then you need to update that as well
 *
 * Anthropic SDK https://github.com/anthropics/anthropic-sdk-typescript/blob/f639ebd1ef07f97c222022bdf10da0f888e3805f/src/resources/completions.ts#L66-L76
 * OpenAI SDK https://github.com/openai/openai-node/blob/45fad1ba633214b89f2c35fd01867e30b90985a4/src/resources/chat/completions.ts#L313
 */
export enum Llm {
  CLAUDE_1 = 'CLAUDE_1',
  CLAUDE_2 = 'CLAUDE_2',
  CLAUDE_3_5_HAIKU = 'CLAUDE_3_5_HAIKU',
  CLAUDE_3_5_SONNET = 'CLAUDE_3_5_SONNET',
  CLAUDE_3_HAIKU = 'CLAUDE_3_HAIKU',
  CLAUDE_3_OPUS = 'CLAUDE_3_OPUS',
  CLAUDE_3_SONNET = 'CLAUDE_3_SONNET',
  COMMAND_R = 'COMMAND_R',
  DEEPSEEK_CODER_33B_INSTRUCT = 'DEEPSEEK_CODER_33B_INSTRUCT',
  GEMINI_1_5_FLASH = 'GEMINI_1_5_FLASH',
  GEMINI_1_5_PRO = 'GEMINI_1_5_PRO',
  // Previously supported
  GEMINI_1_5_PRO_PREVIEW = 'GEMINI_1_5_PRO_PREVIEW',
  GEMMA_2_9B_IT = 'GEMMA_2_9B_IT',
  GPT_3_5_TURBO = 'GPT_3_5_TURBO',
  GPT_4 = 'GPT_4',
  GPT_4O = 'GPT_4O',
  GPT_4O_MINI = 'GPT_4O_MINI',
  GPT_4_TURBO = 'GPT_4_TURBO',
  GRANITE_13B_CHAT_V2 = 'GRANITE_13B_CHAT_V2',
  GRANITE_3_8B_INSTRUCT = 'GRANITE_3_8B_INSTRUCT',
  LLAMA_3_1_70B = 'LLAMA_3_1_70B',
  // NOTE: LLAMA_3_1_70B_INTERNAL is (effectively) the same model as LLAMA_3_1_70B, but with a
  // different provider. **Do not** follow this convention for future models - this is an
  // exceptional case as `LLAMA_3_1_70B_INTERNAL` is an internal Kindo hosted model. We need to
  // come up with a better convention for handling same model but different provider case.
  LLAMA_3_1_70B_INTERNAL = 'LLAMA_3_1_70B_INTERNAL',
  LLAMA_3_1_8B = 'LLAMA_3_1_8B',
  LLAMA_3_70B = 'LLAMA_3_70B',
  MIXTRAL_GROQ = 'MIXTRAL_GROQ',
  O1_MINI = 'O1_MINI',
  O1_PREVIEW = 'O1_PREVIEW',
  QWEN2_72B_INSTRUCT = 'QWEN2_72B_INSTRUCT',
  SAUL_INSTRUCT_V1 = 'SAUL_INSTRUCT_V1',
  WHITERABBITNEO_2_5_QWEN_2_5_32B = 'WHITERABBITNEO_2_5_QWEN_2_5_32B',
  WHITERABBITNEO_33B = 'WHITERABBITNEO_33B',
  WHITERABBITNEO_R1_32B = 'WHITERABBITNEO_R1_32B'
}

export function isLlm(value: string | null): value is Llm {
  return Object.values(Llm).includes(value as Llm);
}

/**
 * LLMs that we do not support for new usage, but cannot remove from the Enum
 * because they are still used in the database.
 *
 * IMPORTANT: When a new LLM is no longer supported and added here, workflow steps should be
 * manually updated to no longer use the deprecated LLM.
 */
export const PREVIOUSLY_SUPPORTED_LLMS = [
  Llm.CLAUDE_1,
  Llm.CLAUDE_2,
  Llm.GEMINI_1_5_PRO_PREVIEW,
  Llm.GPT_4
] as const satisfies readonly Llm[];

/**
 * Internal LLMs are models that are hosted by Kindo,
 * and used for internal tasks like title generation, argentic behavior, etc.
 * Internal LLMs are included in SUPPORTED_LLMS.
 *
 * To disable use of an internal LLM, it should be removed from this array
 * and added to PREVIOUSLY_SUPPORTED_LLMS.
 *
 * Internal Llm's should all have their provider listed as Kindo.
 *
 * IMPORTANT: Since internal LLMs can be used for internal generation/worker tasks,
 * they are not able to have their access disabled or DLP filters applied
 * (although we could let admins remove them as a chat option).
 * For sales reason, we are not including WhiteRabbitNeo in this list now,
 * since we promote the usage of it with DLP.
 *
 * We will need to solve for this case, and decide from the product perspective
 * how to handle security controls with internally hosted LLMs.
 */
export const INTERNAL_LLMS = [
  Llm.LLAMA_3_1_8B,
  Llm.LLAMA_3_1_70B_INTERNAL
] as const satisfies readonly Llm[];

export type InternalLlm = (typeof INTERNAL_LLMS)[number];

export function isInternalLlm(value: string): value is InternalLlm {
  return Object.values(INTERNAL_LLMS).includes(value as InternalLlm);
}

/**
 * Supported LLMs are LLMs that are supported for usage through
 * the Kindo platform. This includes external (from another provider)
 * and internal (Kindo-hosted) LLMs.
 */
export const SUPPORTED_LLMS: Exclude<
  Llm,
  (typeof PREVIOUSLY_SUPPORTED_LLMS)[number]
>[] = Object.values(Llm).filter(isSupportedLlm);

export type SupportedLlm = (typeof SUPPORTED_LLMS)[number];

export function isSupportedLlm(llm: string): llm is SupportedLlm {
  return !PREVIOUSLY_SUPPORTED_LLMS.includes(llm as any);
}

/**
 * Llms that are hosted by an external providers.
 */
export type ExternalLlm = Exclude<SupportedLlm, InternalLlm>;

export const EXTERNAL_LLMS: ExternalLlm[] = SUPPORTED_LLMS.filter(
  (llm: SupportedLlm) => !INTERNAL_LLMS.includes(llm as InternalLlm)
) as ExternalLlm[];

export function isExternalLlm(llm: string): llm is ExternalLlm {
  return EXTERNAL_LLMS.includes(llm as ExternalLlm);
}

export const LLM_DISPLAY_NAMES: Record<Llm, string> = {
  [Llm.CLAUDE_1]: 'Claude 1',
  [Llm.CLAUDE_2]: 'Claude 2',
  [Llm.CLAUDE_3_5_SONNET]: 'Claude 3.5 Sonnet',
  [Llm.CLAUDE_3_HAIKU]: 'Claude 3 Haiku',
  [Llm.CLAUDE_3_OPUS]: 'Claude 3 Opus',
  [Llm.CLAUDE_3_5_HAIKU]: 'Claude 3.5 Haiku',
  [Llm.CLAUDE_3_SONNET]: 'Claude 3 Sonnet',
  [Llm.COMMAND_R]: 'Command R',
  [Llm.DEEPSEEK_CODER_33B_INSTRUCT]: 'DeepSeek Coder 33B',
  [Llm.GEMINI_1_5_FLASH]: 'Gemini 1.5 Flash',
  [Llm.GEMINI_1_5_PRO]: 'Gemini 1.5 Pro',
  [Llm.GEMINI_1_5_PRO_PREVIEW]: 'Gemini 1.5 Pro (Preview)',
  [Llm.GEMMA_2_9B_IT]: 'Gemma 2 9B IT',
  [Llm.GPT_3_5_TURBO]: 'GPT-3.5 Turbo',
  [Llm.GPT_4]: 'GPT-4 32k',
  [Llm.GPT_4O]: 'GPT-4o',
  [Llm.GPT_4O_MINI]: 'GPT-4o mini',
  [Llm.GPT_4_TURBO]: 'GPT-4 Turbo',
  [Llm.GRANITE_13B_CHAT_V2]: 'Granite 13B Chat v2',
  [Llm.GRANITE_3_8B_INSTRUCT]: 'Granite 3.0 8B Instruct',
  [Llm.LLAMA_3_1_70B]: 'Llama 3.1 70B',
  [Llm.LLAMA_3_1_70B_INTERNAL]: 'Llama 3.1 70B Internal',
  [Llm.LLAMA_3_1_8B]: 'Llama 3.1 8B',
  [Llm.LLAMA_3_70B]: 'Llama 3 70B',
  [Llm.MIXTRAL_GROQ]: 'Mixtral',
  [Llm.O1_MINI]: 'o1-mini',
  [Llm.O1_PREVIEW]: 'o1-preview',
  [Llm.QWEN2_72B_INSTRUCT]: 'Qwen 2 72B Instruct',
  [Llm.SAUL_INSTRUCT_V1]: 'Saul Instruct V1',
  [Llm.WHITERABBITNEO_2_5_QWEN_2_5_32B]: 'WhiteRabbitNeo 2.5 32B (Beta)',
  [Llm.WHITERABBITNEO_33B]: 'WhiteRabbitNeo 33B v1.7',
  [Llm.WHITERABBITNEO_R1_32B]: 'WhiteRabbitNeo R1 32B'
};

export enum Provider {
  ANTHROPIC = 'ANTHROPIC',
  AZURE = 'AZURE',
  COHERE = 'COHERE',
  GOOGLE = 'GOOGLE',
  GROQ = 'GROQ',
  HUGGING_FACE = 'HUGGING_FACE',
  IBM = 'IBM',
  KINDO = 'KINDO',
  TOGETHER_AI = 'TOGETHER_AI'
}

export function isProvider(value: unknown): value is Provider {
  return Object.values(Provider).includes(value as Provider);
}

export const PROVIDER_DISPLAY_NAMES: Record<Provider, string> = {
  [Provider.ANTHROPIC]: 'Anthropic',
  [Provider.AZURE]: 'Azure',
  [Provider.COHERE]: 'Cohere',
  [Provider.GOOGLE]: 'Google',
  [Provider.GROQ]: 'Groq',
  [Provider.HUGGING_FACE]: 'Hugging Face',
  [Provider.IBM]: 'IBM',
  [Provider.KINDO]: 'Kindo',
  [Provider.TOGETHER_AI]: 'Together AI'
};

export enum ModelCreator {
  ALIBABA = 'ALIBABA',
  ANTHROPIC = 'ANTHROPIC',
  COHERE = 'COHERE',
  DEEPSEEK = 'DEEPSEEK',
  EQUALL = 'EQUALL',
  GOOGLE = 'GOOGLE',
  HUGGING_FACE = 'HUGGING_FACE',
  IBM = 'IBM',
  KINDO = 'KINDO',
  META = 'META',
  MISTRALAI = 'MISTRALAI',
  OPENAI = 'OPENAI'
}

export const MODEL_CREATOR_DISPLAY_NAMES: Record<ModelCreator, string> = {
  [ModelCreator.ALIBABA]: 'Alibaba',
  [ModelCreator.ANTHROPIC]: 'Anthropic',
  [ModelCreator.COHERE]: 'Cohere',
  [ModelCreator.GOOGLE]: 'Google',
  [ModelCreator.EQUALL]: 'Equall',
  [ModelCreator.META]: 'Meta',
  [ModelCreator.HUGGING_FACE]: 'Hugging Face',
  [ModelCreator.DEEPSEEK]: 'DeepSeek',
  [ModelCreator.IBM]: 'IBM',
  [ModelCreator.KINDO]: 'Kindo',
  [ModelCreator.MISTRALAI]: 'Mistral AI',
  [ModelCreator.OPENAI]: 'OpenAI'
};

export const LLM_TO_PROVIDER: Record<SupportedLlm, Provider> = {
  [Llm.CLAUDE_3_5_SONNET]: Provider.ANTHROPIC,
  [Llm.CLAUDE_3_HAIKU]: Provider.ANTHROPIC,
  [Llm.CLAUDE_3_OPUS]: Provider.ANTHROPIC,
  [Llm.CLAUDE_3_5_HAIKU]: Provider.ANTHROPIC,
  [Llm.CLAUDE_3_SONNET]: Provider.ANTHROPIC,
  [Llm.COMMAND_R]: Provider.COHERE,
  [Llm.DEEPSEEK_CODER_33B_INSTRUCT]: Provider.TOGETHER_AI,
  [Llm.GEMINI_1_5_FLASH]: Provider.GOOGLE,
  [Llm.GEMINI_1_5_PRO]: Provider.GOOGLE,
  [Llm.GEMMA_2_9B_IT]: Provider.GROQ,
  [Llm.GPT_3_5_TURBO]: Provider.AZURE,
  [Llm.GPT_4O]: Provider.AZURE,
  [Llm.GPT_4O_MINI]: Provider.AZURE,
  [Llm.GPT_4_TURBO]: Provider.AZURE,
  [Llm.GRANITE_13B_CHAT_V2]: Provider.IBM,
  [Llm.GRANITE_3_8B_INSTRUCT]: Provider.IBM,
  [Llm.LLAMA_3_1_70B]: Provider.GROQ,
  [Llm.LLAMA_3_70B]: Provider.GROQ,
  [Llm.LLAMA_3_1_70B_INTERNAL]: Provider.KINDO,
  [Llm.LLAMA_3_1_8B]: Provider.KINDO,
  [Llm.MIXTRAL_GROQ]: Provider.GROQ,
  [Llm.O1_MINI]: Provider.AZURE,
  [Llm.O1_PREVIEW]: Provider.AZURE,
  [Llm.QWEN2_72B_INSTRUCT]: Provider.TOGETHER_AI,
  [Llm.SAUL_INSTRUCT_V1]: Provider.HUGGING_FACE,
  [Llm.WHITERABBITNEO_2_5_QWEN_2_5_32B]: Provider.KINDO,
  [Llm.WHITERABBITNEO_33B]: Provider.KINDO,
  [Llm.WHITERABBITNEO_R1_32B]: Provider.KINDO
};

export const LLM_TO_CREATOR: Record<SupportedLlm, ModelCreator> = {
  [Llm.CLAUDE_3_5_SONNET]: ModelCreator.ANTHROPIC,
  [Llm.CLAUDE_3_HAIKU]: ModelCreator.ANTHROPIC,
  [Llm.CLAUDE_3_OPUS]: ModelCreator.ANTHROPIC,
  [Llm.CLAUDE_3_5_HAIKU]: ModelCreator.ANTHROPIC,
  [Llm.CLAUDE_3_SONNET]: ModelCreator.ANTHROPIC,
  [Llm.COMMAND_R]: ModelCreator.COHERE,
  [Llm.DEEPSEEK_CODER_33B_INSTRUCT]: ModelCreator.DEEPSEEK,
  [Llm.GEMINI_1_5_FLASH]: ModelCreator.GOOGLE,
  [Llm.GEMINI_1_5_PRO]: ModelCreator.GOOGLE,
  [Llm.GEMMA_2_9B_IT]: ModelCreator.GOOGLE,
  [Llm.GPT_3_5_TURBO]: ModelCreator.OPENAI,
  [Llm.GPT_4O]: ModelCreator.OPENAI,
  [Llm.GPT_4O_MINI]: ModelCreator.OPENAI,
  [Llm.GPT_4_TURBO]: ModelCreator.OPENAI,
  [Llm.GRANITE_13B_CHAT_V2]: ModelCreator.IBM,
  [Llm.GRANITE_3_8B_INSTRUCT]: ModelCreator.IBM,
  [Llm.LLAMA_3_1_70B]: ModelCreator.META,
  [Llm.LLAMA_3_1_70B_INTERNAL]: ModelCreator.META,
  [Llm.LLAMA_3_1_8B]: ModelCreator.META,
  [Llm.LLAMA_3_70B]: ModelCreator.META,
  [Llm.MIXTRAL_GROQ]: ModelCreator.MISTRALAI,
  [Llm.O1_MINI]: ModelCreator.OPENAI,
  [Llm.O1_PREVIEW]: ModelCreator.OPENAI,
  [Llm.QWEN2_72B_INSTRUCT]: ModelCreator.ALIBABA,
  [Llm.SAUL_INSTRUCT_V1]: ModelCreator.EQUALL,
  [Llm.WHITERABBITNEO_2_5_QWEN_2_5_32B]: ModelCreator.KINDO,
  [Llm.WHITERABBITNEO_33B]: ModelCreator.KINDO,
  [Llm.WHITERABBITNEO_R1_32B]: ModelCreator.KINDO
};

/**
 * These maps are used to convert between the Kindo Llm enum and the LiteLLM model name.
 *
 * IMPORTANT:
 * 1. Add litellm model strings (model_name value in litellm config) to LITELLM_MODEL_STRINGS
 *     a. In the litellm repo, model_name is the name to pass to litellm from external clients
 * 2. Add a mapping from the Llm enum to the litellm model string in LLM_TO_LITELLM_MODELS
 * 3. Any updates to LLM_TO_LITELLM_MODELS must be mirrored in the LITELLM_MODELS_TO_LLM map below.
 * 4. Some Llm's map to multiple LiteLLM models due to the need to support backwards compatibility
 *    for older Kindo versions that may use the old LiteLLM model names.
 */
export enum LitellmModel {
  CLAUDE_3_5_HAIKU = 'claude-3-5-haiku',
  CLAUDE_3_5_SONNET = 'claude-3-5-sonnet',
  CLAUDE_3_5_SONNET_20240620 = 'claude-3-5-sonnet-20240620',
  CLAUDE_3_HAIKU_20240307 = 'claude-3-haiku-20240307',
  CLAUDE_3_OPUS_20240229 = 'claude-3-opus-20240229',
  CLAUDE_3_SONNET_20240229 = 'claude-3-sonnet-20240229',
  COMMAND_R = 'command-r',
  DEEPSEEK_CODER_33B_INSTRUCT = 'deepseek-ai/deepseek-coder-33b-instruct',
  GEMINI_1_5_FLASH = 'gemini-1.5-flash',
  GEMINI_1_5_PRO = 'gemini-1.5-pro',
  GEMMA_2_9B_IT = 'gemma2-9b-it',
  GPT_35_TURBO_0125 = 'azure/gpt-35-turbo-0125',
  GPT_4O = 'azure/gpt-4o',
  GPT_4O_MINI = 'azure/gpt-4o-mini',
  GPT_4_TURBO = 'azure/gpt-4-turbo',
  GRANITE_13B_CHAT_V2 = 'watsonx/ibm/granite-13b-chat-v2',
  GRANITE_3_8B_INSTRUCT = 'watsonx/ibm/granite-3-8b-instruct',
  LLAMA_3_1_70B_VERSATILE = 'groq/llama-3.1-70b-versatile',
  LLAMA_3_70B_8192 = 'groq/llama3-70b-8192',
  META_LLAMA_3_1_70B_INSTRUCT = 'neuralmagic/Meta-Llama-3.1-70B-Instruct-quantized.w4a16',
  META_LLAMA_3_1_8B_INSTRUCT = 'meta-llama/Meta-Llama-3.1-8B-Instruct',
  MIXTRAL_8X7B_32768 = 'groq/mixtral-8x7b-32768',
  O1_MINI = 'o1-mini',
  O1_PREVIEW = 'o1-preview',
  QWEN2_72B_INSTRUCT = 'qwen/qwen2-72b-instruct',
  SAUL_INSTRUCT_V1 = 'huggingface/Saul-Instruct-v1',
  WHITERABBITNEO_2_5_QWEN_2_5_32B = 'WhiteRabbitNeo/WhiteRabbitNeo-2.5-Qwen-2.5-32B',
  WHITERABBITNEO_33B_DEEPSEEK_CODER = '/models/WhiteRabbitNeo-33B-DeepSeekCoder',
  WHITERABBITNEO_R1_32B = 'WhiteRabbitNeo-R1-32B'
}

export const isLitellmModel = (value: string): value is LitellmModel =>
  Object.values(LitellmModel).includes(value as LitellmModel);

export const LLM_TO_LITELLM_MODELS: Record<SupportedLlm, LitellmModel> = {
  [Llm.CLAUDE_3_5_SONNET]: LitellmModel.CLAUDE_3_5_SONNET,
  [Llm.CLAUDE_3_HAIKU]: LitellmModel.CLAUDE_3_HAIKU_20240307,
  [Llm.CLAUDE_3_OPUS]: LitellmModel.CLAUDE_3_OPUS_20240229,
  [Llm.CLAUDE_3_5_HAIKU]: LitellmModel.CLAUDE_3_5_HAIKU,
  [Llm.CLAUDE_3_SONNET]: LitellmModel.CLAUDE_3_SONNET_20240229,
  [Llm.COMMAND_R]: LitellmModel.COMMAND_R,
  [Llm.DEEPSEEK_CODER_33B_INSTRUCT]: LitellmModel.DEEPSEEK_CODER_33B_INSTRUCT,
  [Llm.GEMINI_1_5_FLASH]: LitellmModel.GEMINI_1_5_FLASH,
  [Llm.GEMINI_1_5_PRO]: LitellmModel.GEMINI_1_5_PRO,
  [Llm.GEMMA_2_9B_IT]: LitellmModel.GEMMA_2_9B_IT,
  [Llm.GPT_3_5_TURBO]: LitellmModel.GPT_35_TURBO_0125,
  [Llm.GPT_4O]: LitellmModel.GPT_4O,
  [Llm.GPT_4O_MINI]: LitellmModel.GPT_4O_MINI,
  [Llm.GPT_4_TURBO]: LitellmModel.GPT_4_TURBO,
  [Llm.GRANITE_13B_CHAT_V2]: LitellmModel.GRANITE_13B_CHAT_V2,
  [Llm.GRANITE_3_8B_INSTRUCT]: LitellmModel.GRANITE_3_8B_INSTRUCT,
  [Llm.LLAMA_3_1_70B]: LitellmModel.LLAMA_3_1_70B_VERSATILE,
  [Llm.LLAMA_3_70B]: LitellmModel.LLAMA_3_70B_8192,
  [Llm.LLAMA_3_1_70B_INTERNAL]: LitellmModel.META_LLAMA_3_1_70B_INSTRUCT,
  [Llm.LLAMA_3_1_8B]: LitellmModel.META_LLAMA_3_1_8B_INSTRUCT,
  [Llm.MIXTRAL_GROQ]: LitellmModel.MIXTRAL_8X7B_32768,
  [Llm.O1_MINI]: LitellmModel.O1_MINI,
  [Llm.O1_PREVIEW]: LitellmModel.O1_PREVIEW,
  [Llm.QWEN2_72B_INSTRUCT]: LitellmModel.QWEN2_72B_INSTRUCT,
  [Llm.SAUL_INSTRUCT_V1]: LitellmModel.SAUL_INSTRUCT_V1,
  [Llm.WHITERABBITNEO_2_5_QWEN_2_5_32B]:
    LitellmModel.WHITERABBITNEO_2_5_QWEN_2_5_32B,
  [Llm.WHITERABBITNEO_33B]: LitellmModel.WHITERABBITNEO_33B_DEEPSEEK_CODER,
  [Llm.WHITERABBITNEO_R1_32B]: LitellmModel.WHITERABBITNEO_R1_32B
};

// Some Llm's map to multiple LiteLLM models due to the need to support backwards compatibility
// for older Kindo versions that may use the old LiteLLM model names.
export const LITELLM_MODELS_TO_LLM: Record<LitellmModel, SupportedLlm> = {
  [LitellmModel.CLAUDE_3_5_SONNET]: Llm.CLAUDE_3_5_SONNET,
  [LitellmModel.CLAUDE_3_5_HAIKU]: Llm.CLAUDE_3_5_HAIKU,
  [LitellmModel.CLAUDE_3_5_SONNET_20240620]: Llm.CLAUDE_3_5_SONNET,
  [LitellmModel.CLAUDE_3_HAIKU_20240307]: Llm.CLAUDE_3_HAIKU,
  [LitellmModel.CLAUDE_3_OPUS_20240229]: Llm.CLAUDE_3_OPUS,
  [LitellmModel.CLAUDE_3_SONNET_20240229]: Llm.CLAUDE_3_SONNET,
  [LitellmModel.COMMAND_R]: Llm.COMMAND_R,
  [LitellmModel.DEEPSEEK_CODER_33B_INSTRUCT]: Llm.DEEPSEEK_CODER_33B_INSTRUCT,
  [LitellmModel.GEMINI_1_5_FLASH]: Llm.GEMINI_1_5_FLASH,
  [LitellmModel.GEMINI_1_5_PRO]: Llm.GEMINI_1_5_PRO,
  [LitellmModel.GEMMA_2_9B_IT]: Llm.GEMMA_2_9B_IT,
  [LitellmModel.GPT_35_TURBO_0125]: Llm.GPT_3_5_TURBO,
  [LitellmModel.GPT_4O]: Llm.GPT_4O,
  [LitellmModel.GPT_4O_MINI]: Llm.GPT_4O_MINI,
  [LitellmModel.GPT_4_TURBO]: Llm.GPT_4_TURBO,
  [LitellmModel.GRANITE_13B_CHAT_V2]: Llm.GRANITE_13B_CHAT_V2,
  [LitellmModel.GRANITE_3_8B_INSTRUCT]: Llm.GRANITE_3_8B_INSTRUCT,
  [LitellmModel.LLAMA_3_1_70B_VERSATILE]: Llm.LLAMA_3_1_70B,
  [LitellmModel.LLAMA_3_70B_8192]: Llm.LLAMA_3_70B,
  [LitellmModel.META_LLAMA_3_1_70B_INSTRUCT]: Llm.LLAMA_3_1_70B_INTERNAL,
  [LitellmModel.META_LLAMA_3_1_8B_INSTRUCT]: Llm.LLAMA_3_1_8B,
  [LitellmModel.MIXTRAL_8X7B_32768]: Llm.MIXTRAL_GROQ,
  [LitellmModel.O1_MINI]: Llm.O1_MINI,
  [LitellmModel.O1_PREVIEW]: Llm.O1_PREVIEW,
  [LitellmModel.QWEN2_72B_INSTRUCT]: Llm.QWEN2_72B_INSTRUCT,
  [LitellmModel.SAUL_INSTRUCT_V1]: Llm.SAUL_INSTRUCT_V1,
  [LitellmModel.WHITERABBITNEO_2_5_QWEN_2_5_32B]:
    Llm.WHITERABBITNEO_2_5_QWEN_2_5_32B,
  [LitellmModel.WHITERABBITNEO_33B_DEEPSEEK_CODER]: Llm.WHITERABBITNEO_33B,
  [LitellmModel.WHITERABBITNEO_R1_32B]: Llm.WHITERABBITNEO_R1_32B
};

// LiteLLM serves the OpenAI API, which does not accept a provider parameter. Therefore, the
// provider must be encoded in the model parameter. However, we do not want to encode the provider
// in our model enum Llm. If/when we introduce support for multiple providers per model, we should
// update this function to take in a Provider. This function will then get the LiteLLM model
// using the Llm and the Provider.
export function getLlmFromLitellmModelOrThrow(model: string): SupportedLlm {
  // TODO: Throw a Kindo error code, then use that error code to generate an appropriate error
  // message depending on the context (e.g. app.kindo.ai vs. LiteLLM API).
  // I removed "LiteLLM" from this log in order to not expose to LiteLLM API users that
  // we use LiteLLM under the hood.
  assert(isLitellmModel(model), `No LLM found for model: ${model}`);
  return LITELLM_MODELS_TO_LLM[model];
}

export function getLitellmModelFromLlm(llm: SupportedLlm): LitellmModel {
  return LLM_TO_LITELLM_MODELS[llm];
}

// In the production cluster (us-west1), we have a self-hosted Llama 3.1 8B model.
// In the staging cluster (us-central1) and local development, we use the Azure GPT-3.5 Turbo model
// because self-hosting the model in these environments is not cost effective.
export const AUTOGENERATION_MODEL: SupportedLlm =
  process.env.DEPLOYMENT_ENVIRONMENT === 'production'
    ? Llm.LLAMA_3_1_70B_INTERNAL
    : Llm.GPT_4O_MINI;
