import { SupportedLlm, Tool } from '../constants';
import { WithRequired } from '../utils';

// Matches GenerationResponseSourceNode in llama-indexer
export interface LlamaIndexerGenerationResponseSourceNode {
  content: string;
  id: string;
  // TODO: Type/validate metadata
  metadata: Record<string, any>;
  score: string;
}

// Matches GenerationResponse in llama-indexer
export interface LlamaIndexerGenerationResponse {
  response: string | null;
  sourceNodes: LlamaIndexerGenerationResponseSourceNode[];
  // TODO: Type/validate metadata
  metadata?: Record<string, any> | undefined;
}

export type LlamaIndexerGenerationResponseWithRequiredResponse = WithRequired<
  LlamaIndexerGenerationResponse,
  'response'
>;

// Maps to MessageRole enum from LlamaIndex
export enum LlamaIndexChatMessageRole {
  ASSISTANT = 'assistant',
  FUNCTION = 'function',
  SYSTEM = 'system',
  TOOL = 'tool',
  USER = 'user'
}

export interface LlamaIndexerChatMessage {
  content: string;
  role: LlamaIndexChatMessageRole;
}

export interface LlamaIndexerChatRequest {
  additionalContext: string | null;
  availableModels: SupportedLlm[];
  chatId: string;
  enabledTools: Tool[];
  hasMarkdownFormatting: boolean;
  historicalSpecifiedContent: SpecifiedContent[] | undefined;
  history: LlamaIndexerChatMessage[];
  knowledgeStoreContent: string[] | undefined;
  message: string;
  model: SupportedLlm;
  orgId: string;
  responseChatMessageId: string;
  specifiedContent: SpecifiedContent[] | undefined;
  streamResponse: boolean;
  systemPrompt: string | undefined;
  userChatMessageId: string | undefined;
  userId: string;
}

export interface SpecifiedContent {
  contentId: string;
  extension: string;
  gcsUri: string | undefined;
  s3Key: string | undefined;
}

export interface LlamaIndexerChatResponse {
  response: string | null;
  sourceNodes: LlamaIndexerGenerationResponseSourceNode[];
}

// Matches RunCodeResponse in llama-indexer
export interface LlamaIndexerRunCodeResponse {
  exception: {
    name: string;
    traceback: string;
    value: string;
  } | null;
  logs: {
    stderr: string[];
    stdout: string[];
  };
}
export interface LlamaIndexerRunCodeRequest {
  chatId: string;
  code: string;
  language: string; // TODO: Use SandboxLanguage enum
  responseChatMessageId: string;
}
