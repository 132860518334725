import { CerbosChatAction } from '@kindo/universal';

import { nextTrpc } from '~/trpc';

const useCanShareChat = (chatId: string | undefined) => {
  const { data } = nextTrpc.chats.isAllowed.useQuery(
    {
      action: CerbosChatAction.SHARE_CHAT_WITH_ORG,
      id: chatId ?? ''
    },
    {
      enabled: !!chatId,
      refetchOnWindowFocus: false
    }
  );

  return { canShareChat: data ?? false };
};

export default useCanShareChat;
