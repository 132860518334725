export enum LoadingSpinnerSize {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
  X_LARGE = 'X_LARGE',
}
interface LoadingSpinnerProps {
  size?: LoadingSpinnerSize;
}
const getLoadingSpinnerHeightAndWidth = (size: LoadingSpinnerSize) => {
  switch (size) {
    case LoadingSpinnerSize.SMALL:
      return 'w-4 h-4';
    case LoadingSpinnerSize.MEDIUM:
      return 'w-6 h-6';
    case LoadingSpinnerSize.LARGE:
      return 'w-8 h-8';
    case LoadingSpinnerSize.X_LARGE:
    default:
      return 'w-10 h-10';
  }
};
const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = LoadingSpinnerSize.MEDIUM
}) => <div role="status">
    <svg aria-hidden="true" className={`${getLoadingSpinnerHeightAndWidth(size)} animate-spin text-gray-200 dark:text-gray-600`} fill="none" viewBox="0 0 100 101" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" fill="transparent" r="38" stroke="#E6ECEE" strokeWidth={16} />

      <circle cx="50" cy="50" fill="transparent" r="38" stroke="#2563eb" strokeDasharray="157" strokeDashoffset="75" strokeLinecap="round" strokeWidth={16} />
    </svg>
    <span css={{
    "position": "absolute",
    "width": "1px",
    "height": "1px",
    "padding": "0",
    "margin": "-1px",
    "overflow": "hidden",
    "clip": "rect(0, 0, 0, 0)",
    "whiteSpace": "nowrap",
    "borderWidth": "0"
  }}>Loading...</span>
  </div>;
export default LoadingSpinner;