import {
  Integration,
  WorkflowSupportedIntegration
} from '../integrations.consts';

export const DEFAULT_WORKFLOW_NAME = 'Unnamed Agent';

export enum WorkflowStepType {
  INTEGRATION_ACTION = 'INTEGRATION_ACTION',
  LLM = 'LLM'
}

export enum WorkflowInputType {
  TEXT_OR_CONTENT = 'TEXT_OR_CONTENT',
  TICKETING_INTEGRATION_TICKET = 'TICKETING_INTEGRATION_TICKET'
}

export enum WorkflowIntegrationActionStepType {
  CREATE_COMMENT_ON_TICKET = 'CREATE_COMMENT_ON_TICKET'
}

export const PROMPT_TEMPLATE_WORKFLOW_INPUT_TYPES = [
  WorkflowInputType.TEXT_OR_CONTENT
] as const satisfies Readonly<WorkflowInputType[]>;

export const INTEGRATION_WORKFLOW_INPUT_TYPES = [
  WorkflowInputType.TICKETING_INTEGRATION_TICKET
] as const satisfies Readonly<WorkflowInputType[]>;

export type PromptTemplateWorkflowInputType =
  (typeof PROMPT_TEMPLATE_WORKFLOW_INPUT_TYPES)[number];

export type IntegrationWorkflowInputType =
  (typeof INTEGRATION_WORKFLOW_INPUT_TYPES)[number];

// TODO: Revisit/refactor this once we have category to action mapping.
// Related to [ENG-3778] https://linear.app/kindo/issue/ENG-3778/update-add-and-clean-up-consts-and-types-for-integrations-workflow
// and [ENG-3827] https://linear.app/kindo/issue/ENG-3827/update-integration-type-to-workflowsupportedintegration-type
export const WORKFLOW_INTEGRATION_TO_ACTION: Record<
  WorkflowSupportedIntegration,
  WorkflowIntegrationActionStepType[]
> = {
  [Integration.SERVICENOW]: [
    WorkflowIntegrationActionStepType.CREATE_COMMENT_ON_TICKET
  ],
  [Integration.LINEAR]: [
    WorkflowIntegrationActionStepType.CREATE_COMMENT_ON_TICKET
  ],
  [Integration.JIRA]: [
    WorkflowIntegrationActionStepType.CREATE_COMMENT_ON_TICKET
  ],
  [Integration.AZURE_DEVOPS]: [
    WorkflowIntegrationActionStepType.CREATE_COMMENT_ON_TICKET
  ]
};

export const WORKFLOW_ACTION_LABELS: Record<
  WorkflowIntegrationActionStepType,
  string
> = {
  [WorkflowIntegrationActionStepType.CREATE_COMMENT_ON_TICKET]:
    'Create a comment on ticket'
};

export const WORKFLOW_ACTION_DESCRIPTIONS: Record<
  WorkflowIntegrationActionStepType,
  string
> = {
  [WorkflowIntegrationActionStepType.CREATE_COMMENT_ON_TICKET]:
    'Takes the output from the previous step and writes it as a comment on the ticket'
};

export const WORKFLOW_ACTION_TO_REQUIRED_INPUT_TYPES: Record<
  WorkflowIntegrationActionStepType,
  WorkflowInputType[]
> = {
  [WorkflowIntegrationActionStepType.CREATE_COMMENT_ON_TICKET]: [
    WorkflowInputType.TICKETING_INTEGRATION_TICKET
  ]
};

// TODO: Revisit/refactor this once we have category to action mapping.
// Related to [ENG-3778] https://linear.app/kindo/issue/ENG-3778/update-add-and-clean-up-consts-and-types-for-integrations-workflow
// and [ENG-3827] https://linear.app/kindo/issue/ENG-3827/update-integration-type-to-workflowsupportedintegration-type
export const INTEGRATION_TO_WORKFLOW_INPUT_TYPES: Record<
  WorkflowSupportedIntegration,
  WorkflowInputType[]
> = {
  [Integration.SERVICENOW]: [WorkflowInputType.TICKETING_INTEGRATION_TICKET],
  [Integration.LINEAR]: [WorkflowInputType.TICKETING_INTEGRATION_TICKET],
  [Integration.JIRA]: [WorkflowInputType.TICKETING_INTEGRATION_TICKET],
  [Integration.AZURE_DEVOPS]: [WorkflowInputType.TICKETING_INTEGRATION_TICKET]
};

export enum WorkflowRole {
  EDITOR = 'EDITOR',
  OWNER = 'OWNER',
  VIEWER = 'VIEWER'
}

export const WORKFLOW_ORG_ROLES = [
  WorkflowRole.EDITOR,
  WorkflowRole.OWNER,
  WorkflowRole.VIEWER
] as const satisfies Readonly<WorkflowRole[]>;

export type WorkflowOrgRole = (typeof WORKFLOW_ORG_ROLES)[number];

export const WORKFLOW_USER_ROLES = [
  WorkflowRole.EDITOR,
  WorkflowRole.OWNER,
  WorkflowRole.VIEWER
] as const satisfies Readonly<WorkflowRole[]>;

export type WorkflowUserRole = (typeof WORKFLOW_USER_ROLES)[number];

export const WORKFLOW_PUBLIC_ROLES = [
  WorkflowRole.EDITOR,
  WorkflowRole.VIEWER
] as const satisfies Readonly<WorkflowRole[]>;

export type WorkflowPublicRole = (typeof WORKFLOW_PUBLIC_ROLES)[number];

export enum WorkflowCategory {
  COACHING_AND_HR = 'Coaching and HR',
  CONTENT_CREATION = 'Content Creation',
  CUSTOMER_SUPPORT = 'Customer Support',
  GENERAL = 'General',
  MARKETING_AND_SALES = 'Marketing and Sales',
  PRODUCT_AND_OPERATIONS = 'Product and Operations'
}

export enum WorkflowListSort {
  CATEGORY = 'CATEGORY',
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  RECENTLY_USED = 'RECENTLY_USED',
  UPDATED_AT = 'UPDATED_AT'
}

export enum WorkflowListFilter {
  COMMUNITY_WORKFLOWS = 'COMMUNITY_WORKFLOWS',
  KINDO_WORKFLOWS = 'KINDO_WORKFLOWS',
  MY_WORKFLOWS = 'MY_WORKFLOWS',
  ORG_WORKFLOWS = 'ORG_WORKFLOWS',
  SHARED_WORKFLOWS = 'SHARED_WORKFLOWS'
}

export enum WorkflowTriggerOperator {
  AND = 'AND',
  OR = 'OR'
}

export enum WorkflowTriggerConditionType {
  CONTAINS = 'CONTAINS',
  EQUALS = 'EQUALS',
  NOT_CONTAINS = 'NOT CONTAINS',
  NOT_EQUALS = 'NOT EQUALS',
  REGEX = 'REGEX'
}

export interface WorkflowListArgs {
  category: string | undefined;
  filter: WorkflowListFilter | undefined;
  limit: number | undefined;
  orgId: string;
  orgRole: string;
  sort: WorkflowListSort | undefined;
  sortDesc: boolean | undefined;
  userId: string;
}

export interface WorkflowUser {
  email: string;
  id: string;
  name: string | null;
  role: WorkflowUserRole;
}

/** Type Guards */

export function isWorkflowOrgRole(role: string): role is WorkflowOrgRole {
  return Object.values(WORKFLOW_ORG_ROLES).includes(role as WorkflowOrgRole);
}

export function isWorkflowUserRole(role: string): role is WorkflowUserRole {
  return Object.values(WORKFLOW_USER_ROLES).includes(role as WorkflowUserRole);
}

export function isWorkflowPublicRole(role: string): role is WorkflowPublicRole {
  return Object.values(WORKFLOW_PUBLIC_ROLES).includes(
    role as WorkflowPublicRole
  );
}

export function isWorkflowIntegrationActionStepType(
  type: string
): type is WorkflowIntegrationActionStepType {
  return Object.values(WorkflowIntegrationActionStepType).includes(
    type as WorkflowIntegrationActionStepType
  );
}

export function isWorkflowInputType(type: string): type is WorkflowInputType {
  return Object.values(WorkflowInputType).includes(type as WorkflowInputType);
}

export function isWorkflowTriggerOperator(
  operator: string
): operator is WorkflowTriggerOperator {
  return Object.values(WorkflowTriggerOperator).includes(
    operator as WorkflowTriggerOperator
  );
}

export function isWorkflowTriggerConditionType(
  type: string
): type is WorkflowTriggerConditionType {
  return Object.values(WorkflowTriggerConditionType).includes(
    type as WorkflowTriggerConditionType
  );
}

// TODO: Revisit/refactor this once we have category to action mapping.
// Related to [ENG-3778] https://linear.app/kindo/issue/ENG-3778/update-add-and-clean-up-consts-and-types-for-integrations-workflow
// and [ENG-3827] https://linear.app/kindo/issue/ENG-3827/update-integration-type-to-workflowsupportedintegration-type
export function isIntegrationInWorkflowIntegrationToActionMap(
  integration: Integration
): integration is keyof typeof WORKFLOW_INTEGRATION_TO_ACTION {
  return Object.keys(WORKFLOW_INTEGRATION_TO_ACTION).includes(integration);
}

// Add this new type guard function
export function isIntegrationWorkflowInputType(
  type: string
): type is IntegrationWorkflowInputType {
  return INTEGRATION_WORKFLOW_INPUT_TYPES.includes(
    type as IntegrationWorkflowInputType
  );
}
