import { css as _css } from "styled-components";
import _styled from "styled-components";
import { TypographyWeight, TypographyWrap } from '../Typography';
import { Color, Icon, Icons, Size, ToolTip, Typography, TypographySize } from '~/components/core';
const ChipContainer = _styled.div<{
  $maxWidth: number | TwStyle;
  onClick?: (() => void) | undefined;
}>(({
  onClick,
  $maxWidth
}) => [{
  "display": "flex",
  "minWidth": "0px",
  "borderRadius": "9999px",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(230 232 239 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(249 250 251 / var(--tw-bg-opacity))",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem",
  "paddingLeft": "0.5rem",
  "paddingRight": "1rem",
  "&:hover": {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(77 114 128 / var(--tw-border-opacity))"
  }
}, onClick ? {
  "cursor": "pointer"
} : {
  "cursor": "default"
}, typeof $maxWidth === 'number' ? _css({
  maxWidth: `${$maxWidth}px`
}) : $maxWidth // Max width
]);
const LabelAndIconsContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "space-between",
  "gap": "0.25rem"
});
const LabelAndStartIconContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "flex-start",
  "gap": "0.25rem"
});
interface ChipProps {
  label: string;
  endIcon?: Icon;
  // Number = pixels, string = tw class
  maxWidth?: number | TwStyle;
  onClick?: () => void;
  startIcon?: Icon;
  tooltip?: string;
}
export const Chip = ({
  label,
  endIcon,
  onClick,
  startIcon,
  tooltip: tooltipText,
  maxWidth = {
    "maxWidth": "100%"
  }
}: ChipProps) => <ToolTip content={tooltipText}>
    <ChipContainer $maxWidth={maxWidth} onClick={onClick ?? undefined}>
      <LabelAndIconsContainer>
        <LabelAndStartIconContainer>
          {!!startIcon && <Icons color={Color.NAVY} icon={startIcon} size={Size.MEDIUM} />}
          <Typography color={Color.BLACK} size={TypographySize.X_SMALL} weight={TypographyWeight.SEMI_NORMAL} wrap={maxWidth ? TypographyWrap.NO_WRAP : TypographyWrap.NORMAL}>
            {label}
          </Typography>
        </LabelAndStartIconContainer>
        {!!endIcon && <Icons color={Color.NAVY} icon={endIcon} size={Size.MEDIUM} />}
      </LabelAndIconsContainer>
    </ChipContainer>
  </ToolTip>;
export default Chip;