export enum CerbosResource {
  API_KEY = 'api_key',
  AUDIT_LOG = 'audit_log',
  CHAT = 'chat',
  CONTENT = 'content',
  FILE = 'file',
  ORG = 'org',
  ORG_INVITE = 'org_invite',
  TEAM = 'team',
  USER = 'user',
  WORKFLOW = 'workflow',
  //  TODO: Cerbos currently does not support the exists operator when used on the resource.
  //  To get around this, we created a separate resource for the list endpoint.
  //  https://cerboscommunity.slack.com/archives/C02A364JYMQ/p1711148574786499
  WORKFLOW_FOR_LIST = 'workflow_for_list'
}

export enum CerbosRole {
  'USER' = 'user'
}

// Actions
export enum CerbosApiKeyAction {
  MANAGE_API_KEY = 'MANAGE_API_KEY'
}

export enum CerbosAuditLogAction {
  LIST = 'LIST'
}

export enum CerbosChatAction {
  READ_CHAT = 'READ_CHAT',
  SHARE_CHAT_WITH_ORG = 'SHARE_CHAT_WITH_ORG',
  WRITE_CHAT = 'WRITE_CHAT'
}

export enum CerbosContentAction {
  DELETE_CONTENT = 'DELETE_CONTENT',
  GET_CONTENT = 'GET_CONTENT'
}

export enum CerbosFileAction {
  DELETE_FILE = 'DELETE_FILE',
  GET_FILE = 'GET_FILE'
}

export enum CerbosWorkflowAction {
  DELETE_WORKFLOW = 'DELETE_WORKFLOW',
  EDIT_WORKFLOW = 'EDIT_WORKFLOW',
  SHARE_WORKFLOW_WITH_ORG = 'SHARE_WORKFLOW_WITH_ORG',
  SHARE_WORKFLOW_WITH_PUBLIC = 'SHARE_WORKFLOW_WITH_PUBLIC',
  SHARE_WORKFLOW_WITH_USER = 'SHARE_WORKFLOW_WITH_USER',
  VIEW_WORKFLOW_WITH_STATIC_CONTENT = 'VIEW_WORKFLOW_WITH_STATIC_CONTENT'
}

export enum CerbosOrgAction {
  MANAGE_ORG = 'MANAGE_ORG',
  VIEW_ORG = 'VIEW_ORG'
}

export enum CerbosOrgInviteAction {
  DELETE_INVITE = 'DELETE_INVITE',
  UPDATE_INVITE = 'UPDATE_INVITE'
}

export enum CerbosTeamAction {
  INVITE = 'INVITE',
  LIST_INVITES = 'LIST_INVITES',
  REMOVE_USER = 'REMOVE_USER',
  UNINVITE = 'UNINVITE'
}

export const CERBOS_WORKFLOW_SHARE_ACTIONS = [
  CerbosWorkflowAction.SHARE_WORKFLOW_WITH_ORG,
  CerbosWorkflowAction.SHARE_WORKFLOW_WITH_PUBLIC,
  CerbosWorkflowAction.SHARE_WORKFLOW_WITH_USER
] as const satisfies CerbosWorkflowAction[];

export type CerbosWorkflowShareAction = {
  [K in (typeof CERBOS_WORKFLOW_SHARE_ACTIONS)[number]]: boolean;
};
