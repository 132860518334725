import _styled from "styled-components";
import { TooltipPlacement } from '../ToolTip/ToolTip';
import { Color, Icon, Icons, Size, ToolTip, ToolTipColor, Typography } from '~/components/core';
import { TypographySize, TypographyWeight, TypographyWrap } from '~/components/core/Typography';
const SelectOptionContainer = _styled.li<{
  $destructive: boolean;
  $disabled: boolean;
}>(({
  $destructive,
  $disabled
}) => [{
  "position": "relative",
  "display": "flex",
  "width": "100%",
  "userSelect": "none",
  "gap": "0.25rem",
  "borderRadius": "0.375rem",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem",
  "&:hover": {
    "backgroundColor": "#8CAEBA33"
  }
}, $destructive && {
  "&:hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(254 226 226 / var(--tw-bg-opacity))"
  }
}, $disabled && {
  "cursor": "not-allowed",
  "opacity": "0.5"
}]);
const TextContainer = _styled.div<{
  $startIcon: boolean;
}>`
  ${({
  $startIcon
}) => $startIcon ? {
  "marginLeft": "0.5rem"
} : {}}
`;
const OptionContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "row",
  "justifyContent": "space-between"
});
const SelectedContentContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "row",
  "alignItems": "center",
  "justifyContent": "space-between",
  "gap": "0.5rem"
});
const LabelStartIconAndDescriptionContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "justifyContent": "flex-start"
});
const LabelAndStartIconContainer = _styled.div({
  "display": "flex",
  "flexDirection": "row",
  "alignItems": "center",
  "justifyContent": "flex-start"
});
const SuffixAndEndIconContainer = _styled.div({
  "display": "flex",
  "flexDirection": "row",
  "alignItems": "center",
  "justifyContent": "flex-start",
  "gap": "0.25rem",
  "> :not([hidden]) ~ :not([hidden])": {
    "--tw-space-x-reverse": "0",
    "marginRight": "calc(0.25rem * var(--tw-space-x-reverse))",
    "marginLeft": "calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))"
  },
  "paddingLeft": "1.25rem"
});
export interface SelectOptionProps<Value extends string | null = string> {
  destructive: boolean;
  disabled: boolean;
  isSelected: boolean;
  label: string;
  onClick: (value: Value) => void;
  value: Value;
  description?: string | undefined;
  endIcon?: {
    icon: Icon;
    color?: Color;
    size?: Size;
  } | undefined;
  prefix?: string;
  startIcon?: {
    icon: Icon;
    color?: Color;
    size?: Size;
  } | undefined;
  suffix?: {
    text: string;
    color?: Color;
    size?: TypographySize;
  };
  tooltip?: string;
}
export const SelectOption = <Value extends string | null = string,>({
  label,
  onClick,
  value,
  isSelected,
  disabled,
  startIcon,
  endIcon,
  prefix,
  suffix,
  destructive,
  tooltip,
  description
}: SelectOptionProps<Value>) => {
  const iconAndTextColor = destructive ? Color.RED : Color.NAVY;
  return <ToolTip color={ToolTipColor.BLUE} content={tooltip ?? ''} disabled={disabled} offset={[0, 8]} placement={TooltipPlacement.TOP_START}>
      <SelectOptionContainer $destructive={destructive} $disabled={disabled} data-value={value} onClick={() => !disabled && onClick(value)}>
        <OptionContainer>
          <SelectedContentContainer>
            <LabelStartIconAndDescriptionContainer>
              <LabelAndStartIconContainer>
                {!!prefix && prefix}
                {!!startIcon && <Icons color={startIcon.color} icon={startIcon.icon} size={startIcon.size} />}
                <TextContainer $startIcon={!!startIcon}>
                  <Typography color={iconAndTextColor} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
                    {label}
                  </Typography>
                </TextContainer>
              </LabelAndStartIconContainer>
              {description && <Typography color={Color.GRAY} size={TypographySize.X_SMALL} weight={TypographyWeight.NORMAL}>
                  {description}
                </Typography>}
            </LabelStartIconAndDescriptionContainer>
            {isSelected && !!value && <Icons color={Color.NAVY} icon={Icon.CHECK} />}
          </SelectedContentContainer>
          <SuffixAndEndIconContainer>
            {!!suffix && <Typography color={suffix.color ? suffix.color : Color.NAVY} size={suffix.size ? suffix.size : TypographySize.SMALL} wrap={TypographyWrap.NO_WRAP}>
                {suffix.text}
              </Typography>}
            {!!endIcon && <Icons color={endIcon.color} icon={endIcon.icon} size={endIcon.size} />}
          </SuffixAndEndIconContainer>
        </OptionContainer>
      </SelectOptionContainer>
    </ToolTip>;
};
export default SelectOption;