const Background = () => <div css={{
  "position": "absolute",
  "left": "0px",
  "top": "0px",
  "zIndex": "-40",
  "height": "100vh",
  "width": "100vw",
  "overflow": "hidden"
}}>
    <div css={{
    "position": "absolute",
    "height": "100%",
    "width": "100%",
    "backgroundImage": "linear-gradient(to right, var(--tw-gradient-stops))",
    "--tw-gradient-from": "#f8f3ff var(--tw-gradient-from-position)",
    "--tw-gradient-to": "#d8ffe3 var(--tw-gradient-to-position)",
    "--tw-gradient-stops": "var(--tw-gradient-from), #e7fcf9 var(--tw-gradient-via-position), var(--tw-gradient-to)"
  }}>
      {' '}
    </div>
    <div style={{
    maskImage: 'linear-gradient(to bottom, transparent, transparent, black)',
    WebkitMaskImage: 'linear-gradient(to bottom,transparent, transparent, black)'
  }} css={{
    "position": "absolute",
    "height": "100%",
    "width": "100%",
    "backgroundImage": "linear-gradient(to right, var(--tw-gradient-stops))",
    "--tw-gradient-from": "#c5c0ff var(--tw-gradient-from-position)",
    "--tw-gradient-to": "#a5febb var(--tw-gradient-to-position)",
    "--tw-gradient-stops": "var(--tw-gradient-from), #bfeef4 var(--tw-gradient-via-position), var(--tw-gradient-to)"
  }}>
      {' '}
    </div>
    <div css={{
    "position": "absolute",
    "left": "72vw",
    "top": "33.333333%",
    "height": "75%",
    "width": "33.333333%",
    "--tw-rotate": "142deg",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "borderRadius": "9999px",
    "backgroundImage": "linear-gradient(to right, var(--tw-gradient-stops))",
    "--tw-gradient-from": "#fbffd166 var(--tw-gradient-from-position)",
    "--tw-gradient-to": "#f6ff8e66 var(--tw-gradient-to-position)",
    "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)",
    "--tw-blur": "blur(125px)",
    "filter": "var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)"
  }}>
      {' '}
    </div>
  </div>;
export default Background;