import { CerbosOrgAction } from '@kindo/universal';

import { useAppSelector } from './typedReduxHooks';

import { nextTrpc } from '~/trpc';

const useCanAccessSecuritySettings = () => {
  //  Redux
  const { org } = useAppSelector((state) => state.user);
  const orgCanAccessSecuritySettings =
    org?.paymentTierConfig.accessSecuritySettings;

  // Queries
  const { data: userCanManageOrganization } = nextTrpc.orgs.isAllowed.useQuery({
    action: CerbosOrgAction.MANAGE_ORG
  });

  const userCanAccessSecuritySettings =
    !!userCanManageOrganization && !!orgCanAccessSecuritySettings;

  return {
    userCanAccessSecuritySettings,
    userCanManageOrganization,
    orgCanAccessSecuritySettings
  };
};

export default useCanAccessSecuritySettings;
