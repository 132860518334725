import { css as _css } from "styled-components";
import _styled from "styled-components";
const ToggleLoadingSpinner = _styled.div<{
  $checked: boolean;
}>(({
  $checked
}) => [{
  "position": "absolute",
  "top": "0.125rem",
  "display": "flex",
  "height": "1.25rem",
  "width": "1.25rem",
  "alignItems": "center",
  "justifyContent": "center",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms"
},
// Setting the hardcoded right property to make the transition apply
$checked ? {
  "right": "2px"
} : {
  "right": "22px"
}, _css`
      span {
        animation: spin 1s linear infinite;
        z-index: 1;

        @keyframes spin {
          0% {
            transform: rotate(360deg);
          }
          100% {
            transform: rotate(0deg);
          }
        }
      }
    `]);
export default ToggleLoadingSpinner;