// ALL the constants defined here are temporary.
// These are implemented for milestone 1&2 only.

export enum AuditEventType {
  CHAT_CREATED = 'CHAT_CREATED',
  CHAT_MESSAGE_SENT = 'CHAT_MESSAGE_SENT',
  CHAT_ORG_ACCESS_UPDATED = 'CHAT_ORG_ACCESS_UPDATED',
  CODE_EXECUTED = 'CODE_EXECUTED',
  COPILOT_CHAT_MESSAGE = 'COPILOT_CHAT_MESSAGE_SENT',
  COPILOT_CODE_COMPLETION = 'COPILOT_CODE_COMPLETION_USED',
  DLP_FILTER_APPLIED = 'DLP_FILTER_APPLIED',
  FILE_ADDED = 'FILE_ADDED',
  KINDO_PUBLIC_API_REQUEST_MADE = 'KINDO_PUBLIC_API_REQUEST_MADE',
  LLM_REQUEST_FAILED = 'LLM_REQUEST_FAILED',
  LLM_REQUEST_SUCCEEDED = 'LLM_REQUEST_SUCCEEDED',
  ORG_INVITE_ACCEPTED = 'ORG_INVITE_ACCEPTED',
  ORG_INVITE_DELETED = 'ORG_INVITE_DELETED',
  ORG_INVITE_SENT = 'ORG_INVITE_SENT',
  ORG_INVITE_UPDATED = 'ORG_INVITE_UPDATED',
  ORG_MEMBER_ADDED = 'ORG_MEMBER_ADDED',
  ORG_MEMBER_REMOVED = 'ORG_MEMBER_REMOVED',
  ORG_MEMBER_ROLE_CHANGED = 'ORG_MEMBER_ROLE_CHANGED',
  ORG_NAME_CHANGED = 'ORG_SETTING_CHANGED',
  ORG_SETTINGS_UPDATED = 'ORG_SETTINGS_UPDATED',
  SECURITY_CONFIG_UPDATED = 'SECURITY_CONFIG_UPDATED',
  SECURITY_CUSTOM_DLP_CREATED = 'SECURITY_CUSTOM_DLP_CREATED',
  SECURITY_CUSTOM_DLP_DELETED = 'SECURITY_CUSTOM_DLP_DELETED',
  USER_API_KEY_CREATED = 'USER_API_KEY_ADDED',
  USER_API_KEY_DISABLED = 'USER_API_KEY_DISABLED',
  USER_API_KEY_ENABLED = 'USER_API_KEY_ENABLED',
  USER_API_KEY_REMOVED = 'USER_API_KEY_REMOVED',
  USER_SETTING_CHANGED = 'USER_SETTING_CHANGED',
  WORKFLOW_CREATED = 'WORKFLOW_CREATED',
  WORKFLOW_DELETED = 'WORKFLOW_DELETED',
  WORKFLOW_ORG_ROLE_UPDATED = 'WORKFLOW_ORG_ROLE_UPDATED',
  WORKFLOW_PUBLIC_ROLE_UPDATED = 'WORKFLOW_PUBLIC_ROLE_UPDATED',
  WORKFLOW_SHARED_TO_USER = 'WORKFLOW_SHARED_TO_USER',
  WORKFLOW_TRIGGERED = 'WORKFLOW_TRIGGERED',
  WORKFLOW_UNSHARED_FROM_USER = 'WORKFLOW_UNSHARED_FROM_USER',
  WORKFLOW_UPDATED = 'WORKFLOW_UPDATED'
}

// This array was created to improve query performance of audit log exporter.
// Using "notIn" in the query is faster than using "in".
export const SYSTEM_INITIATED_AUDIT_EVENT_TYPES: AuditEventType[] = [
  AuditEventType.COPILOT_CODE_COMPLETION,
  AuditEventType.DLP_FILTER_APPLIED,
  AuditEventType.LLM_REQUEST_FAILED,
  AuditEventType.LLM_REQUEST_SUCCEEDED,
  AuditEventType.WORKFLOW_TRIGGERED
];

export const USER_INITIATED_AUDIT_EVENT_TYPES: AuditEventType[] = [
  AuditEventType.CHAT_CREATED,
  AuditEventType.CHAT_MESSAGE_SENT,
  AuditEventType.CHAT_ORG_ACCESS_UPDATED,
  AuditEventType.CODE_EXECUTED,
  AuditEventType.COPILOT_CHAT_MESSAGE,
  AuditEventType.FILE_ADDED,
  AuditEventType.KINDO_PUBLIC_API_REQUEST_MADE,
  AuditEventType.ORG_INVITE_ACCEPTED,
  AuditEventType.ORG_INVITE_DELETED,
  AuditEventType.ORG_INVITE_SENT,
  AuditEventType.ORG_INVITE_UPDATED,
  AuditEventType.ORG_MEMBER_ADDED,
  AuditEventType.ORG_MEMBER_REMOVED,
  AuditEventType.ORG_MEMBER_ROLE_CHANGED,
  AuditEventType.ORG_NAME_CHANGED,
  AuditEventType.ORG_SETTINGS_UPDATED,
  AuditEventType.SECURITY_CONFIG_UPDATED,
  AuditEventType.SECURITY_CUSTOM_DLP_CREATED,
  AuditEventType.SECURITY_CUSTOM_DLP_DELETED,
  AuditEventType.USER_API_KEY_CREATED,
  AuditEventType.USER_API_KEY_DISABLED,
  AuditEventType.USER_API_KEY_ENABLED,
  AuditEventType.USER_API_KEY_REMOVED,
  AuditEventType.USER_SETTING_CHANGED,
  AuditEventType.WORKFLOW_CREATED,
  AuditEventType.WORKFLOW_DELETED,
  AuditEventType.WORKFLOW_ORG_ROLE_UPDATED,
  AuditEventType.WORKFLOW_PUBLIC_ROLE_UPDATED,
  AuditEventType.WORKFLOW_SHARED_TO_USER,
  AuditEventType.WORKFLOW_UNSHARED_FROM_USER,
  AuditEventType.WORKFLOW_UPDATED
];

export enum AuditEventTypeGroupFilter {
  USER_INITIATED = 'userInitiated'
}

export type AuditEventTypeFilter = AuditEventTypeGroupFilter | AuditEventType;
