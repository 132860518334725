import { css as _css } from "styled-components";
import _styled from "styled-components";
import { Color, TEXT_COLORS } from '../constants';
import { withLink } from '../hocs';
import { TypographyAlignment, TypographyCasing, TypographyLetterSpacing, TypographySize, TypographyWeight, TypographyWrap } from './Typography.consts';
const typographyCasing: Record<TypographyCasing, TwStyle> = {
  [TypographyCasing.LOWERCASE]: {
    "textTransform": "lowercase"
  },
  [TypographyCasing.NONE]: {},
  [TypographyCasing.UPPERCASE]: {
    "textTransform": "uppercase"
  },
  [TypographyCasing.CAPITALIZE]: {
    "textTransform": "capitalize"
  }
};
const typographySize: Record<TypographySize, TwStyle> = {
  [TypographySize.X_SMALL]: {
    "fontSize": "0.75rem",
    "lineHeight": "1rem"
  },
  [TypographySize.SMALL]: {
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem"
  },
  [TypographySize.MEDIUM]: {
    "fontSize": "1rem",
    "lineHeight": "1.5rem"
  },
  [TypographySize.LARGE]: {
    "fontSize": "1.125rem",
    "lineHeight": "1.75rem"
  },
  [TypographySize.H4]: {
    "fontSize": "1.25rem",
    "lineHeight": "1.75rem"
  },
  [TypographySize.H3]: {
    "fontSize": "1.5rem",
    "lineHeight": "2rem"
  },
  [TypographySize.H2]: {
    "fontSize": "1.875rem",
    "lineHeight": "2.25rem"
  },
  [TypographySize.H1]: {
    "fontSize": "2.25rem",
    "lineHeight": "2.5rem"
  }
};
const typographyWeight: Record<TypographyWeight, TwStyle> = {
  [TypographyWeight.BOLD]: {
    "fontWeight": "700"
  },
  [TypographyWeight.LIGHT]: {
    "fontWeight": "300"
  },
  [TypographyWeight.MEDIUM]: {
    "fontWeight": "468"
  },
  [TypographyWeight.NORMAL]: {
    "fontWeight": "400"
  },
  [TypographyWeight.SEMI_BOLD]: {
    "fontWeight": "600"
  },
  [TypographyWeight.SEMI_LIGHT]: {
    "fontWeight": "350"
  },
  [TypographyWeight.SEMI_NORMAL]: {
    "fontWeight": "375"
  }
};
const typographyLetterSpacing: Record<TypographyLetterSpacing, TwStyle> = {
  [TypographyLetterSpacing.NORMAL]: {
    "letterSpacing": "0em"
  },
  [TypographyLetterSpacing.WIDE]: {
    "letterSpacing": "0.025em"
  }
};
const typographyAlignment: Record<TypographyAlignment, TwStyle> = {
  [TypographyAlignment.CENTER]: {
    "textAlign": "center"
  },
  [TypographyAlignment.LEFT]: {
    "textAlign": "left"
  },
  [TypographyAlignment.RIGHT]: {
    "textAlign": "right"
  },
  [TypographyAlignment.JUSTIFY]: {
    "textAlign": "justify"
  }
};
const typographyWrap: Record<TypographyWrap, TwStyle> = {
  [TypographyWrap.NO_WRAP]: {
    "whiteSpace": "nowrap"
  },
  [TypographyWrap.NORMAL]: {
    "whiteSpace": "normal"
  },
  [TypographyWrap.PRE_WRAP]: {
    "whiteSpace": "pre-wrap"
  }
};
const getTypographySize = (size: TypographySize | number) => {
  if (typeof size === 'number') {
    return _css({
      fontSize: `${size}px`
    });
  }
  return typographySize[size];
};
const TypographyContainer = _styled.span<{
  $alignment: TypographyAlignment;
  $casing: TypographyCasing;
  $color: Color;
  $fitContent: boolean;
  $fullWidth: boolean;
  $isLink: boolean;
  $italic: boolean;
  $letterSpacing: TypographyLetterSpacing;
  $maxWidth: number | TwStyle;
  $size: TypographySize | number;
  $weight: TypographyWeight;
  $wrap: TypographyWrap;
}>(({
  $alignment,
  $casing,
  $color,
  $fitContent,
  $fullWidth,
  $isLink,
  $italic,
  $letterSpacing,
  $maxWidth,
  $size,
  $weight,
  $wrap
}) => [{
  "overflow": "hidden",
  "textOverflow": "ellipsis"
}, typographyAlignment[$alignment],
// Alignment
typographyCasing[$casing],
// Casing
TEXT_COLORS[$color],
// Color
$fitContent && {
  "minWidth": "fit-content"
}, typeof $maxWidth === 'number' ? _css({
  maxWidth: `${$maxWidth}px`
}) : $maxWidth,
// Max width
$fullWidth && {
  "width": "100%"
},
// Full width
$isLink && {
  "&:hover": {
    "opacity": "0.5"
  }
},
// isLink
$italic && {
  "fontStyle": "italic"
},
// italic
getTypographySize($size),
// Size
typographyWeight[$weight],
// Weight
typographyLetterSpacing[$letterSpacing],
// Letter spacing
typographyWrap[$wrap] // Wrap
]);
interface TypographyProps {
  children: React.ReactNode;
  alignment?: TypographyAlignment;
  casing?: TypographyCasing;
  color?: Color | undefined;
  fitContent?: boolean;
  fullWidth?: boolean;
  href?: string;
  italic?: boolean;
  letterSpacing?: TypographyLetterSpacing;
  maxWidth?: number | TwStyle | undefined;
  onClick?: () => void;
  // Number = pixels, string = tw class
  size?: TypographySize | number;
  // Number = pixels
  weight?: TypographyWeight;
  wrap?: TypographyWrap | undefined;
}
const Typography: React.FC<TypographyProps> = ({
  children,
  alignment = TypographyAlignment.LEFT,
  casing = TypographyCasing.NONE,
  color = Color.BLACK,
  fullWidth = false,
  href,
  italic = false,
  letterSpacing = TypographyLetterSpacing.NORMAL,
  fitContent = false,
  maxWidth = {
    "maxWidth": "100%"
  },
  onClick,
  size = TypographySize.MEDIUM,
  weight = TypographyWeight.NORMAL,
  wrap = TypographyWrap.NORMAL
}) => withLink(<TypographyContainer $alignment={alignment} $casing={casing} $color={color} $fitContent={fitContent} $fullWidth={fullWidth} $isLink={!!href} $italic={italic} $letterSpacing={letterSpacing} $maxWidth={maxWidth} $size={size} $weight={weight} $wrap={wrap} onClick={onClick}
// Caused by issue with SSR and local storage for chat modal selected
// TODO: Find a better solution and remove this
suppressHydrationWarning>
      {children}
    </TypographyContainer>, {
  href
});
export default Typography;