import _styled from "styled-components";
import { RequireOnlyOne } from '@kindo/universal';
import { Color, ICON_BUTTON_SIZE_STYLES, Size } from '../constants';
import { withLink } from '../hocs';
import { Icon } from '../Icon/Icon.consts';
import Icons, { IconProps } from '../Icon/Icons';
import { ToolTipColor } from '../ToolTip';
import ToolTip, { TooltipPlacement } from '../ToolTip/ToolTip';
export enum IconButtonType {
  FILLED = 'FILLED',
  OUTLINED = 'OUTLINED',
  SIMPLE = 'SIMPLE',
}
const ICON_BUTTON_TYPE_STYLES: Record<IconButtonType, TwStyle> = {
  [IconButtonType.OUTLINED]: {
    "borderWidth": "1px",
    "borderColor": "#095A7C58",
    "backgroundColor": "transparent"
  },
  [IconButtonType.SIMPLE]: {
    "backgroundColor": "transparent"
  },
  [IconButtonType.FILLED]: {
    "borderColor": "#095A7C58",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(253 252 251 / var(--tw-bg-opacity))"
  }
};
const IconButtonContainer = _styled.button<{
  $active: boolean;
  $color: Color;
  $size: Size | number;
  $type: IconButtonType;
  disabled: boolean;
}>(({
  $active,
  $color,
  $size,
  $type,
  disabled
}) => [{
  "display": "flex",
  "height": "fit-content",
  "width": "fit-content",
  "flexShrink": "0",
  "alignItems": "center",
  "justifyContent": "center",
  "borderRadius": "9999px",
  "padding": "9px",
  "&:hover": {
    "--tw-bg-opacity": "0.2",
    "backgroundColor": "rgb(128 237 153 / var(--tw-bg-opacity))"
  }
},
// Base styles
ICON_BUTTON_TYPE_STYLES[$type], typeof $size === 'number' ? {
  "padding": "0.5rem"
} : ICON_BUTTON_SIZE_STYLES[$size], disabled && {
  "cursor": "not-allowed",
  "opacity": "0.8",
  "&:hover": {
    "backgroundColor": "transparent"
  }
}, $active && {
  "--tw-bg-opacity": "0.2",
  "backgroundColor": "rgb(128 237 153 / var(--tw-bg-opacity))"
}, $type === IconButtonType.OUTLINED && $color === Color.RED &&
// TODO: Add a new salmon color and use that border color
{
  "--tw-border-opacity": "0.2",
  "borderColor": "rgb(255 144 138 / var(--tw-border-opacity))",
  "&:hover": {
    "--tw-border-opacity": "1"
  }
}]);
interface BaseIconButtonProps {
  href: string;
  icon: Icon;
  onClick: () => void;
  type: IconButtonType;
  active?: boolean;
  color?: Color;
  'data-id'?: string | undefined;
  disabled?: boolean;
  invert?: boolean;
  size?: IconProps['size'];
  title?: string;
  tooltip?: string;
}

// Ensures that either onClick or href is provided
type IconButtonProps = RequireOnlyOne<BaseIconButtonProps, 'onClick' | 'href'>;
const IconButton: React.FC<IconButtonProps> = ({
  icon,
  type,
  onClick,
  href,
  active = false,
  color = Color.NAVY,
  'data-id': dataId,
  invert = false,
  size = Size.MEDIUM,
  title,
  disabled = false,
  tooltip
}) => {
  const getIconColor = () => {
    if (disabled) return Color.DISABLED;
    return color;
  };
  const iconColor = getIconColor();
  const handleOnClick = (e: React.MouseEvent) => {
    // Do NOT stop propagation if it's a link,
    // it will break next routing from working properly
    if (onClick) {
      e.stopPropagation();
      onClick();
    }
  };
  return withLink(<ToolTip color={ToolTipColor.BLUE} content={tooltip ?? ''} offset={[0, 8]} placement={TooltipPlacement.TOP_START}>
      <IconButtonContainer $active={active} $color={iconColor} $size={size} $type={type} data-id={dataId} disabled={disabled} onClick={handleOnClick} title={title}>
        <Icons color={iconColor} icon={icon} invert={invert} size={size} />
      </IconButtonContainer>
    </ToolTip>, {
    href
  });
};
export default IconButton;