import _styled from "styled-components";
import { Button, ButtonType, Modal, ModalWidth } from '~/components/core';
import { nextTrpc } from '~/trpc';
interface TermsOfServiceModalProps {
  onComplete: () => void;
}
const TermsOfServiceModalContent = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyItems": "center",
  "gap": "1rem"
});
const TermsOfServiceModalHeader = _styled.div({
  "fontSize": "1.5rem",
  "lineHeight": "2rem",
  "fontWeight": "600",
  "--tw-text-opacity": "1",
  "color": "rgb(9 90 124 / var(--tw-text-opacity))"
});
const TermsOfServiceInfo = _styled.div({
  "textAlign": "center",
  "fontSize": "0.875rem",
  "lineHeight": "1.25rem",
  "--tw-text-opacity": "1",
  "color": "rgb(107 114 128 / var(--tw-text-opacity))"
});
const TermsOfServiceLink = _styled.a({
  "textDecorationLine": "underline",
  "textDecorationColor": "#9ca3af"
});
const TermsOfServiceModal: React.FC<TermsOfServiceModalProps> = ({
  onComplete
}) => {
  const signTermsOfServiceMutation = nextTrpc.users.signTermsOfService.useMutation();
  const handleAcceptTerms = async () => {
    try {
      await signTermsOfServiceMutation.mutateAsync();
    } catch (error) {
      console.error('Failed to sign terms of service', error);
    } finally {
      onComplete();
    }
  };
  const {
    isLoading
  } = signTermsOfServiceMutation;
  return <Modal open width={ModalWidth['2XL']}>
      <TermsOfServiceModalContent>
        <TermsOfServiceModalHeader>
          Please Review Terms
        </TermsOfServiceModalHeader>
        <TermsOfServiceInfo>
          By clicking "Agree", you confirm that you have read and understood our{' '}
          <TermsOfServiceLink href="http://kindo.ai/terms-of-use" target="_blank">
            Terms of Use
          </TermsOfServiceLink>
          ,{' '}
          <TermsOfServiceLink href="http://kindo.ai/terms-of-service" target="_blank">
            Terms of Service
          </TermsOfServiceLink>
          , and{' '}
          <TermsOfServiceLink href="http://kindo.ai/privacy-policy" target="_blank">
            Privacy Policy
          </TermsOfServiceLink>
          , and agree to comply with them.
        </TermsOfServiceInfo>
        <Button fullWidth label="Agree" loading={isLoading} onClick={handleAcceptTerms} type={ButtonType.FILLED} />
      </TermsOfServiceModalContent>
    </Modal>;
};
export default TermsOfServiceModal;