import { css as _css } from "styled-components";
import _styled from "styled-components";
import { RuleSet } from 'styled-components';
import { Color, Size, TEXT_COLORS } from '../constants';
import { ICON_SVGS, Icon } from './Icon.consts';
export interface IconProps {
  // Undefined color uses the original svg fill,
  // should only be used for things like integration logos
  color: Color | undefined;
  icon: Icon;
  invert?: boolean;
  size?: Size | number | undefined;
}
const iconSize: Record<Size, RuleSet<Object>> = {
  [Size.X_SMALL]: _css({
    svg: {
      "height": "0.5rem",
      "width": "0.5rem"
    }
  }),
  [Size.SMALL]: _css({
    svg: {
      "height": "0.75rem",
      "width": "0.75rem"
    }
  }),
  [Size.MEDIUM]: _css({
    svg: {
      "height": "1rem",
      "width": "1rem"
    }
  }),
  [Size.LARGE]: _css({
    svg: {
      "height": "1.25rem",
      "width": "1.25rem"
    }
  }),
  [Size.X_LARGE]: _css({
    svg: {
      "height": "1.5rem",
      "width": "1.5rem"
    }
  })
};
const getIconSize = (size: Size | number) => {
  if (typeof size === 'number') {
    return _css({
      svg: {
        height: `${size}px`,
        width: `${size}px`
      }
    });
  }
  return iconSize[size];
};
const IconContainer = _styled.span<{
  $color: Color | undefined;
  $invert: boolean;
  $size: Size | number;
}>(({
  $color,
  $size,
  $invert
}) => [$color && TEXT_COLORS[$color], getIconSize($size), $invert && {
  "--tw-rotate": "180deg",
  "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
}]);
const Icons: React.FC<IconProps> = ({
  icon,
  color,
  size = Size.MEDIUM,
  invert = false
}) => {
  const IconSVG = ICON_SVGS[icon];
  return <IconContainer $color={color} $invert={invert} $size={size}>
      <IconSVG />
    </IconContainer>;
};
export default Icons;