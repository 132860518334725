import { WorkflowUser } from '@kindo/universal';

import { Workflow } from '~/types';

const useIsSharedWorkflow = ({
  workflow,
  authorizedUsers
}: {
  authorizedUsers: WorkflowUser[];
  workflow: Workflow;
}): { isSharedWorkflow: boolean } => {
  const isSharedWorkflow =
    workflow?.orgRole !== null ||
    workflow?.publicRole !== null ||
    authorizedUsers.length > 0;

  return { isSharedWorkflow };
};

export default useIsSharedWorkflow;
