import { OrgSetting, OrgSettingsConfig } from '@kindo/universal';
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult
} from '@tanstack/react-query';
import { TRPCClientErrorLike } from '@trpc/client';

import { nextTrpc } from '~/trpc';

interface UseOrgSettingsReturnObject {
  isLoading: boolean;
  orgSettings: OrgSettingsConfig | undefined;
  refetchOrgSettings: (
    options?: (RefetchOptions & RefetchQueryFilters<OrgSetting>) | undefined
  ) => Promise<
    QueryObserverResult<OrgSettingsConfig, TRPCClientErrorLike<any>>
  >;
  updateOrgSetting: <K extends OrgSetting>(
    setting: K,
    value: OrgSettingsConfig[K],
    onSuccess?: () => void,
    onError?: (error: TRPCClientErrorLike<any>) => void
  ) => void;
}

const useOrgSettings = (): UseOrgSettingsReturnObject => {
  // Custom hooks
  // Get the settings associated with the org
  const {
    data: orgSettings,
    isLoading,
    refetch: refetchOrgSettings
  } = nextTrpc.orgs.getOrgSettings.useQuery();

  // /Update OrgSetting mutation
  const updateOrgSettingsMutation = nextTrpc.orgs.updateOrgSettings.useMutation(
    {
      // Default fallback
      onError: (error) => console.error(error)
    }
  );

  // Generic function for updating org settings with custom callbacks
  const updateOrgSetting = <K extends OrgSetting>(
    setting: K,
    value: OrgSettingsConfig[K],
    onSuccess?: () => void,
    onError?: (error: TRPCClientErrorLike<any>) => void
  ) => {
    updateOrgSettingsMutation.mutate(
      {
        [setting]: value
      },
      {
        onSuccess: () => onSuccess && onSuccess(),
        onError: (error) => onError && onError(error)
      }
    );
  };

  return {
    orgSettings,
    updateOrgSetting,
    refetchOrgSettings,
    isLoading
  };
};

export default useOrgSettings;
