import {
  EXPRESS_CONTENT_BASE_URL,
  EXPRESS_CONTENT_DIRECT_ROUTE,
  EXPRESS_FILE_UPLOAD_ROUTE,
  EXPRESS_ROUTE,
  RequireAtLeastOne,
  SETTINGS_ROUTE
} from '@kindo/universal';

// eslint-disable-next-line prefer-destructuring
export const BASE_NEXT_URL = process.env.NEXT_PUBLIC_BASE_NEXT_URL;
export const BASE_API_URL = process.env.NEXT_PUBLIC_UM_API_BASE_URL;
export const BASE_INTERNAL_API_URL = `${BASE_API_URL}/internal`;
export const TRPC_URL = `${BASE_API_URL}/trpc`;
export const INTERNAL_API_TRPC_URL = `${BASE_INTERNAL_API_URL}/trpc`;
export const INTERNAL_API_OPENAPI_URL = `${BASE_INTERNAL_API_URL}/openapi`;

export const ROOT_ROUTE = '/';

export const WORKSTATION_ROUTE = ROOT_ROUTE;
export const LIBRARY_STORAGE_ROUTE = '/storage';
export const WORKFLOW_BUILDER_ROUTE = '/builder';

// Chat query params
export const CHAT_ID_QUERY_PARAM = 'chatId';

// Workflow query params
export const WORKFLOW_ID_QUERY_PARAM = 'workflowId';

// Audit Log query params
export const AUDIT_LOG_ROUTE = '/audit-log';
export const AUDIT_LOG_EVENT_ID_QUERY_PARAM = 'auditEventId';

export const NUDGE_QUERY_PARAM = 'type';
export const NUDGE_QUERY_PARAM_VALUE = 'nudge';

// Nav routes
export const LIBRARY_ROUTE = '/library';
export const SECURITY_SETTINGS_ROUTE = '/security-settings';
export const ORG_SECURITY_SETTINGS_ROUTE = '/org-security';
export const PLUGINS_ROUTE = '/plugins';
export { SETTINGS_ROUTE }; // Lives in kindo-universal since its used on backend for Stripe callback
export const INTEGRATIONS_ROUTE = `${SETTINGS_ROUTE}/integrations`;
export const SUPPORT_ROUTE = '/support';
export const PROFILE_ROUTE = '/profile';

export const LOGIN_ROUTE = '/login';
export const MAGIC_LINK_ROUTE = '/magic-link';
export const SSO_ROUTE = '/sso';
export const MAGIC_LINK_ERROR_PARAM = 'magicLinkError';
export const LOGIN_RETURN_URL_PARAM = 'returnUrl';
export const ERROR_PARAM = 'error';

export const STATUS_ROUTE = '/status';

export const VERIFY_ROUTE = '/verify';

export const NEXT_API_ROUTE = '/api';
export const VERSION_ROUTE = NEXT_API_ROUTE + '/version';

// Marketing Website
export const MARKETING_WEBSITE_BASE_URL = 'https://kindo.ai';

export const MARKETING_WEBSITE_CONTACT_URL =
  MARKETING_WEBSITE_BASE_URL + '/contact';

type WorkstationRouteInput = RequireAtLeastOne<{
  chatId: string;
  workflowId?: string;
}>;

// Functions to build routes
export const getWorkstationRoute = ({
  chatId,
  workflowId
}: WorkstationRouteInput): string =>
  `${WORKSTATION_ROUTE}?` +
  (chatId ? `${CHAT_ID_QUERY_PARAM}=${chatId}` : '') +
  (workflowId ? `&${WORKFLOW_ID_QUERY_PARAM}=${workflowId}` : '');

export const getWorkflowBuilderRoute = (workflowId: string): string =>
  `${WORKFLOW_BUILDER_ROUTE}?${WORKFLOW_ID_QUERY_PARAM}=${workflowId}`;

// Content URL that bypasses the proxy
// Proxy limit of 4mb does not allow for large files to be downloaded
export const getContentDirectFetchUrl = ({
  contentId,
  isDownload,
  isPlaintext
}: {
  contentId: string;
  isDownload: boolean;
  isPlaintext: boolean;
}) =>
  `${BASE_API_URL}${EXPRESS_CONTENT_DIRECT_ROUTE}/${contentId}?download=${isDownload}&plaintext=${isPlaintext}`;

export const getContentProxyFetchUrl = (contentId: string) =>
  `${EXPRESS_CONTENT_BASE_URL}/${contentId}`;

// Custom subdomain used to bypass Cloudflare and avoid upload limits
const FILE_UPLOAD_BASE_URL = process.env.NEXT_PUBLIC_FILE_UPLOAD_BASE_URL;
export const EXPRESS_FILE_UPLOAD_DIRECT_URL =
  FILE_UPLOAD_BASE_URL + EXPRESS_ROUTE + EXPRESS_FILE_UPLOAD_ROUTE;
