import _styled from "styled-components";
import React, { useEffect } from 'react';
import RadioQuestion from './RadioQuestion';
import TextQuestion from './TextQuestion';
import { Button, ButtonType, Color, Typography, TypographySize } from '~/components/core';
const StepContainer = _styled.div({
  "justifySelf": "end"
});
const QuestionAreaContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "> :not([hidden]) ~ :not([hidden])": {
    "--tw-space-y-reverse": "0",
    "marginTop": "calc(1rem * calc(1 - var(--tw-space-y-reverse)))",
    "marginBottom": "calc(1rem * var(--tw-space-y-reverse))"
  }
});
const StepButtonContainer = _styled.div({
  "display": "flex",
  "gap": "2rem",
  "paddingTop": "1rem"
});
export enum SurveyStepType {
  RADIO = 'RADIO',
  TEXT = 'TEXT',
}
interface BaseSurveyStepProps {
  allowSkip: boolean;
  prompt: string | ((value: string) => string);
  stepName: string;
  type: SurveyStepType;
  initialValue?: string | ((user: any) => string);
  placeholder?: string;
}
interface TextStepData extends BaseSurveyStepProps {
  type: SurveyStepType.TEXT;
}
interface RadioStepData extends BaseSurveyStepProps {
  options: string[];
  type: SurveyStepType.RADIO;
  otherOptionLabel?: string;
}
export type SurveyStepData = TextStepData | RadioStepData;
type SurveyStepProps = {
  initialValue: string | null;
  onSkip: () => void;
  onSubmit: (name: string) => void;
  stepData: SurveyStepData | null;
  stepNumber: number;
  totalSteps: number;
};
const SurveyStep = ({
  initialValue,
  onSkip,
  onSubmit,
  stepData,
  stepNumber,
  totalSteps
}: SurveyStepProps) => {
  const [value, setValue] = React.useState(initialValue || '');
  const handleContinue = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(value);
    setValue(initialValue || '');
  };

  // NOTE: this is needed to init value when the step number changes
  // handleContinue above has the wrong data when it runs
  useEffect(() => {
    setValue(initialValue || '');
  }, [initialValue, stepNumber]);
  if (!stepData) return null;
  const promptText = typeof stepData.prompt === 'function' ? stepData.prompt(value) : stepData.prompt;
  const placeholderText = stepData.placeholder || 'Type your answer here';
  const showWelcomeMessage = stepNumber === 1 || null;
  return <>
      {showWelcomeMessage && <>
          <Typography color={Color.NAVY} size={TypographySize.H3}>
            Welcome to Kindo!
          </Typography>
          <Typography>
            To give you the best possible experience, we'd like to ask you a
            couple of quick questions:
          </Typography>
        </>}
      <StepContainer>
        <Typography color={Color.GRAY} size={TypographySize.SMALL}>
          {stepNumber} OF {totalSteps}
        </Typography>
        <form onSubmit={handleContinue}>
          <QuestionAreaContainer>
            {stepData.type === SurveyStepType.TEXT && <TextQuestion onChange={setValue} placeholder={placeholderText} prompt={promptText} value={value} />}
            {stepData.type === SurveyStepType.RADIO && <RadioQuestion onChange={setValue} options={stepData.options} otherOptionLabel={stepData.otherOptionLabel} placeholder={placeholderText} prompt={promptText} value={value} />}
          </QuestionAreaContainer>
          <StepButtonContainer>
            <Button disabled={!value} label="Continue" onClick={handleContinue} type={ButtonType.FILLED} />
            {stepData.allowSkip && <Button label="Skip" onClick={onSkip} type={ButtonType.TEXT} />}
          </StepButtonContainer>
        </form>
      </StepContainer>
    </>;
};
export default SurveyStep;