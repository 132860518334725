import _styled from "styled-components";
import { useId } from 'react';
export const SurveyOptionContainer = _styled.label({
  "position": "relative",
  "marginTop": "0.25rem !important",
  "display": "flex",
  "cursor": "pointer",
  "flexDirection": "row",
  "justifyContent": "space-between",
  "borderRadius": "9999px",
  "borderWidth": "1px",
  "borderColor": "transparent",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(255 255 255 / var(--tw-bg-opacity))",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem",
  "--tw-text-opacity": "1",
  "color": "rgb(9 90 124 / var(--tw-text-opacity))",
  "&:has(:checked)": {
    "--tw-text-opacity": "1",
    "color": "rgb(22 25 37 / var(--tw-text-opacity))"
  }
});
const RadioUnchecked = _styled.span({
  "display": "block",
  "height": "1.25rem",
  "width": "1.25rem",
  "borderRadius": "9999px",
  "borderWidth": "2px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(156 163 175 / var(--tw-border-opacity))",
  ".group:focus-within &": {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(30 211 176 / var(--tw-border-opacity))"
  }
});
const RadioChecked = _styled.span({
  "position": "absolute",
  "left": "0.25rem",
  "top": "0.25rem",
  "height": "0.75rem",
  "width": "0.75rem",
  "borderRadius": "9999px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(30 211 176 / var(--tw-bg-opacity))"
});
const HiddenRadioInput = _styled.input({
  "position": "absolute",
  "right": "1.25rem",
  "top": "1rem",
  "opacity": "0"
});
interface SurveyOptionProps {
  checked: boolean;
  onChange: (checked?: boolean) => void;
  value: string;
  onClick?: () => void;
}

// TODO: Make a core radio button component that doesn't rely on inputs, labels, and checks
// But uses normal react state
const SurveyOption = ({
  checked,
  value,
  onChange,
  onClick
}: SurveyOptionProps) => {
  const id = useId();
  const optionId = `requirement-option-${id}`;
  return <SurveyOptionContainer className="group" htmlFor={optionId}>
      {value}
      <span css={{
      "position": "relative"
    }}>
        <RadioUnchecked />
        {checked ? <RadioChecked /> : null}
      </span>
      <HiddenRadioInput checked={checked} css={{
      "position": "absolute",
      "right": "1.25rem",
      "top": "1rem",
      "opacity": "0"
    }} id={optionId} name="radioQuestion" onChange={e => onChange(e.target.checked)} onClick={onClick} type="radio" value={value} />
    </SurveyOptionContainer>;
};
export default SurveyOption;