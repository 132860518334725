import { css as _css } from "styled-components";
import _styled from "styled-components";
import { RequireOnlyOne } from '@kindo/universal';
import { BUTTON_SIZE_STYLES, Color, Size } from '../constants';
import { withLink } from '../hocs';
import { Icon, Icons } from '../Icon';
import { LoadingSpinner, LoadingSpinnerSize } from '../LoadingSpinner';
import { ToolTip, ToolTipColor } from '../ToolTip';
import { TooltipPlacement } from '../ToolTip/ToolTip';
import { Typography, TypographySize, TypographyWeight, TypographyWrap } from '../Typography';
export enum ButtonType {
  DASHED = 'DASHED',
  FILLED = 'FILLED',
  OUTLINED = 'OUTLINED',
  OUTLINED_LIGHT = 'OUTLINED_LIGHT',
  TEXT = 'TEXT',
}

// Styled for the button based on the button type
const BUTTON_TYPE_STYLES: Record<ButtonType, TwStyle> = {
  [ButtonType.DASHED]: {
    "borderWidth": "1px",
    "borderStyle": "dashed",
    "backgroundColor": "transparent"
  },
  [ButtonType.FILLED]: {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(9 90 124 / var(--tw-bg-opacity))",
    "&:enabled:hover": {
      "backgroundColor": "#095A7C58"
    }
  },
  [ButtonType.OUTLINED]: {
    "borderWidth": "1px",
    "backgroundColor": "transparent"
  },
  [ButtonType.OUTLINED_LIGHT]: {
    "borderWidth": "1px",
    "borderColor": "#8CAEBA33",
    "backgroundColor": "transparent",
    "&:focus": {
      "--tw-border-opacity": "1",
      "borderColor": "rgb(77 114 128 / var(--tw-border-opacity))"
    },
    "&:enabled:hover": {
      "--tw-border-opacity": "1",
      "borderColor": "rgb(77 114 128 / var(--tw-border-opacity))"
    }
  },
  [ButtonType.TEXT]: {
    "borderStyle": "none",
    "backgroundColor": "transparent",
    "paddingLeft": "0px",
    "&:enabled:hover": {
      "opacity": "0.5"
    }
  }
};

// Color for Icon and Label text based on the button type
const BUTTON_ICON_TEXT_COLOR: Record<ButtonType, Color> = {
  [ButtonType.DASHED]: Color.NAVY,
  [ButtonType.FILLED]: Color.PALE_GREEN,
  [ButtonType.OUTLINED]: Color.NAVY,
  [ButtonType.OUTLINED_LIGHT]: Color.NAVY,
  [ButtonType.TEXT]: Color.NAVY
};
const StyledButton = _styled.button<{
  $active: boolean;
  $fullWidth: boolean;
  $hasEndIcon: boolean;
  $hasStartIcon: boolean;
  $maxWidth: number | TwStyle;
  $size: Size;
  $type: ButtonType;
  disabled: boolean;
}>(({
  $active,
  $type,
  $fullWidth,
  $maxWidth,
  $hasEndIcon,
  $hasStartIcon,
  $size,
  disabled
}) => [{
  "display": "flex",
  "width": "fit-content",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "0.25rem",
  "whiteSpace": "nowrap",
  "borderRadius": "9999px",
  "borderColor": "#095A7C58",
  "paddingLeft": "0.75rem",
  "paddingRight": "0.75rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem"
},
// Base styles
!disabled && {
  "&:hover": {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(9 90 124 / var(--tw-border-opacity))"
  }
},
// Hover if not disabled, can be overridden by type styles
{
  "&:disabled": {
    "cursor": "not-allowed",
    "opacity": "0.5"
  }
},
// Disabled overrides
$hasStartIcon && {
  "paddingLeft": "10px"
},
// Padding left based on start icon
$hasEndIcon && {
  "paddingRight": "10px"
},
// Padding right based on end icon
BUTTON_TYPE_STYLES[$type],
// Button type styles
BUTTON_SIZE_STYLES[$size],
// Button size styles
typeof $maxWidth === 'number' ? _css({
  maxWidth: `${$maxWidth}px`
}) : $maxWidth,
// Max width
$active && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(229 254 223 / var(--tw-bg-opacity))"
},
// Active styling
$fullWidth && {
  "width": "100%"
} // Full width styling
]);
export const ToolTipSpanWrapper = _styled.span<{
  $fullWidth: boolean;
}>`
  ${({
  $fullWidth
}) => $fullWidth && {
  "width": "100%"
}}
`;
interface BaseButtonProps {
  href: string;
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  type: ButtonType;
  active?: boolean;
  // Fills button with another color to represent an active state
  'data-id'?: string | undefined;
  disabled?: boolean | undefined;
  endIcon?: Icon | undefined;
  fullWidth?: boolean;
  loading?: boolean | undefined;
  // Number = pixels, string = tw class
  maxWidth?: number | TwStyle;
  size?: Size;
  startIcon?: Icon | undefined;
  suffix?: string | undefined;
  textColor?: Color | undefined;
  tooltip?: string | undefined;
}

// Ensures that either onClick or href is provided
type ButtonProps = RequireOnlyOne<BaseButtonProps, 'onClick' | 'href'>;
const Button: React.FC<ButtonProps> = ({
  label,
  type,
  onClick,
  href,
  active = false,
  'data-id': dataId,
  disabled = false,
  endIcon,
  fullWidth = false,
  loading = false,
  maxWidth = {
    "maxWidth": "100%"
  },
  size = Size.MEDIUM,
  textColor,
  startIcon,
  suffix,
  tooltip
}) => {
  const getIconAndTextColor = () => {
    if (textColor) return textColor;
    switch (true) {
      case active:
        return Color.NAVY;
      default:
        return BUTTON_ICON_TEXT_COLOR[type];
    }
  };
  const iconAndTextColor = getIconAndTextColor();
  const disableOnClick = disabled || loading;
  return withLink(<ToolTip color={ToolTipColor.BLUE} content={tooltip ?? ''} offset={[0, 8]} placement={TooltipPlacement.TOP_START}>
      {/* Wrap tooltip in span so it works while button is disabled */}
      {/* TODO: Find a way to fix this span's width, or remove it */}
      <ToolTipSpanWrapper $fullWidth={fullWidth}>
        <StyledButton $active={active} $fullWidth={fullWidth} $hasEndIcon={!!endIcon} $hasStartIcon={!!startIcon} $maxWidth={maxWidth} $size={size} $type={type} data-id={dataId} disabled={disabled} onClick={disableOnClick ? undefined : onClick}>
          {!loading && <>
              {startIcon && <Icons color={iconAndTextColor} icon={startIcon} />}
              <Typography color={iconAndTextColor} size={TypographySize.SMALL} weight={type === ButtonType.TEXT ? TypographyWeight.SEMI_BOLD : TypographyWeight.MEDIUM} wrap={TypographyWrap.NO_WRAP}>
                {label}
                {suffix && <Typography color={Color.GRAY} size={TypographySize.SMALL} weight={TypographyWeight.LIGHT}>
                    {' ' + suffix}
                  </Typography>}
              </Typography>
              {endIcon && <Icons color={iconAndTextColor} icon={endIcon} />}
            </>}
          {loading && <LoadingSpinner size={LoadingSpinnerSize.SMALL} />}
        </StyledButton>
      </ToolTipSpanWrapper>
    </ToolTip>, {
    href
  });
};
export default Button;