import { CerbosWorkflowAction } from '@kindo/universal';

import { nextTrpc } from '~/trpc';

const useCanShareWorkflow = (workflowId: string | undefined) => {
  const { data: workflowSharePermissions } =
    nextTrpc.workflows.checkUserSharePermissionsOnWorkflow.useQuery(
      {
        workflowId: workflowId ?? ''
      },
      {
        enabled: !!workflowId,
        refetchOnWindowFocus: false
      }
    );

  return {
    canShareWorkflow: workflowSharePermissions?.canShareWorkflow ?? false,
    canShareWorkflowWithPublic:
      workflowSharePermissions?.[
        CerbosWorkflowAction.SHARE_WORKFLOW_WITH_PUBLIC
      ] ?? false
  };
};

export default useCanShareWorkflow;
